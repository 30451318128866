exports.components = {
  "component---src-entities-redirect-pages-redirect-page-jsx": () => import("./../../../src/@entities/redirect_pages/redirect_page.jsx" /* webpackChunkName: "component---src-entities-redirect-pages-redirect-page-jsx" */),
  "component---src-entities-rides-rides-detail-tsx": () => import("./../../../src/@entities/rides/rides.detail.tsx" /* webpackChunkName: "component---src-entities-rides-rides-detail-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-entry-template-fullwidth-jsx": () => import("./../../../src/templates/entry.template.fullwidth.jsx" /* webpackChunkName: "component---src-templates-entry-template-fullwidth-jsx" */),
  "component---src-templates-entry-template-standard-jsx": () => import("./../../../src/templates/entry.template.standard.jsx" /* webpackChunkName: "component---src-templates-entry-template-standard-jsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "slice---src-modules-cards-cards-slice-tsx": () => import("./../../../src/@modules/cards/cards.slice.tsx" /* webpackChunkName: "slice---src-modules-cards-cards-slice-tsx" */),
  "slice---src-modules-html-code-html-code-slice-tsx": () => import("./../../../src/@modules/HTML_code/HTML_code.slice.tsx" /* webpackChunkName: "slice---src-modules-html-code-html-code-slice-tsx" */),
  "slice---src-modules-logos-grid-logos-grid-slice-tsx": () => import("./../../../src/@modules/logosGrid/logosGrid.slice.tsx" /* webpackChunkName: "slice---src-modules-logos-grid-logos-grid-slice-tsx" */),
  "slice---src-modules-private-link-private-link-slice-tsx": () => import("./../../../src/@modules/private_link/private_link.slice.tsx" /* webpackChunkName: "slice---src-modules-private-link-private-link-slice-tsx" */),
  "slice---src-modules-rides-list-rides-list-slice-tsx": () => import("./../../../src/@modules/rides_list/rides_list.slice.tsx" /* webpackChunkName: "slice---src-modules-rides-list-rides-list-slice-tsx" */),
  "slice---src-slices-blank-index-tsx": () => import("./../../../src/@slices/blank/index.tsx" /* webpackChunkName: "slice---src-slices-blank-index-tsx" */),
  "slice---src-slices-modules-calendar-index-tsx": () => import("./../../../src/@slices/modules/calendar/index.tsx" /* webpackChunkName: "slice---src-slices-modules-calendar-index-tsx" */),
  "slice---src-slices-modules-cta-index-tsx": () => import("./../../../src/@slices/modules/cta/index.tsx" /* webpackChunkName: "slice---src-slices-modules-cta-index-tsx" */),
  "slice---src-slices-modules-cta-section-index-tsx": () => import("./../../../src/@slices/modules/ctaSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-cta-section-index-tsx" */),
  "slice---src-slices-modules-fact-text-box-section-index-tsx": () => import("./../../../src/@slices/modules/factTextBoxSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-fact-text-box-section-index-tsx" */),
  "slice---src-slices-modules-factbox-section-index-tsx": () => import("./../../../src/@slices/modules/factboxSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-factbox-section-index-tsx" */),
  "slice---src-slices-modules-faq-standard-index-tsx": () => import("./../../../src/@slices/modules/faqStandard/index.tsx" /* webpackChunkName: "slice---src-slices-modules-faq-standard-index-tsx" */),
  "slice---src-slices-modules-feed-list-item-default-index-tsx": () => import("./../../../src/@slices/modules/feedListItem-default/index.tsx" /* webpackChunkName: "slice---src-slices-modules-feed-list-item-default-index-tsx" */),
  "slice---src-slices-modules-full-image-index-tsx": () => import("./../../../src/@slices/modules/fullImage/index.tsx" /* webpackChunkName: "slice---src-slices-modules-full-image-index-tsx" */),
  "slice---src-slices-modules-hero-index-tsx": () => import("./../../../src/@slices/modules/hero/index.tsx" /* webpackChunkName: "slice---src-slices-modules-hero-index-tsx" */),
  "slice---src-slices-modules-home-jumbo-index-tsx": () => import("./../../../src/@slices/modules/homeJumbo/index.tsx" /* webpackChunkName: "slice---src-slices-modules-home-jumbo-index-tsx" */),
  "slice---src-slices-modules-iframes-index-tsx": () => import("./../../../src/@slices/modules/iframes/index.tsx" /* webpackChunkName: "slice---src-slices-modules-iframes-index-tsx" */),
  "slice---src-slices-modules-image-carousel-index-tsx": () => import("./../../../src/@slices/modules/imageCarousel/index.tsx" /* webpackChunkName: "slice---src-slices-modules-image-carousel-index-tsx" */),
  "slice---src-slices-modules-image-text-section-index-tsx": () => import("./../../../src/@slices/modules/imageTextSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-image-text-section-index-tsx" */),
  "slice---src-slices-modules-newsletter-index-tsx": () => import("./../../../src/@slices/modules/newsletter/index.tsx" /* webpackChunkName: "slice---src-slices-modules-newsletter-index-tsx" */),
  "slice---src-slices-modules-search-section-index-tsx": () => import("./../../../src/@slices/modules/searchSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-search-section-index-tsx" */),
  "slice---src-slices-modules-separator-index-tsx": () => import("./../../../src/@slices/modules/separator/index.tsx" /* webpackChunkName: "slice---src-slices-modules-separator-index-tsx" */),
  "slice---src-slices-modules-testimonials-index-tsx": () => import("./../../../src/@slices/modules/testimonials/index.tsx" /* webpackChunkName: "slice---src-slices-modules-testimonials-index-tsx" */),
  "slice---src-slices-modules-text-block-index-tsx": () => import("./../../../src/@slices/modules/textBlock/index.tsx" /* webpackChunkName: "slice---src-slices-modules-text-block-index-tsx" */)
}

